<template>
  <TTView>
    <VRow>
      <VCol>
        <CompanyCreateForm
          :entity="company"
          :loading="loading"
          @update:name="company.name = $event"
          @update:imageFile="company.imageFile = $event"
          @submit="handleCreateCompany"
          @cancel="handleCancel"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import _ from 'lodash';
import CompanyCreateForm from '../../../components/v2/CompanyCreateForm.vue';

const viewCompanyDTO = {
  id: null,
  name: null,
  active: true,
  accountId: null,
  image: null,
  imageFile: null,
};

export default {
  name: 'CompanyCreate',
  components: { CompanyCreateForm },
  inject: ['Names'],
  data() {
    return {
      loading: false,
      company: _.cloneDeep(viewCompanyDTO),
    };
  },
  methods: {
    async handleCreateCompany() {
      try {
        this.loading = true;
        const dto = _.cloneDeep({ ...this.company });
        const app = new this.$di.ddd.Accounts();
        const [company] = await app.services.account.createCompany(dto);
        this.$di.notify.snackSuccess('Компания успешно создана');
        this.$router.push({
          name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW,
          params: {
            accountId: company.accountId,
            companyId: company.id,
          },
        });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
