<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      Создание компании
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <CompanyAvatar
                is-upload
                label="Логотип"
                :value="entity.imageFile"
                @update="$emit('update:imageFile', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                label="Название компании"
                placeholder="ООО Ромашка"
                outlined
                :value="entity.name"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        :disabled="!entity.name"
        @click="handleSubmit"
      >
        Создать компанию
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import CompanyAvatar from './ui/CompanyAvatar.vue';

export default {
  name: 'CompanyCreateForm',
  components: { CompanyAvatar },
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};

</script>
